import { useState } from "react";
import loader from "../../img/loader.svg";

const DEFAULT_STATE = {
    "Name": "",
    "Email": "",
    "Contact-Telephone": "",
    "Message": "",
}

const ContactForm = () => {
    const [formData, setFormData] = useState(DEFAULT_STATE);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [formSent, setFormSent] = useState(false);

    const updateForm = (e) => {
        const target = e.target.name;
        setFormData(prev => {
            return {
                ...prev,
                [target]: e.target.value,
            }
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const URL = "https://formspree.io/f/xwkawqwq";
        
        setLoading(true);

        const params = {
            method: "POST",
            body: JSON.stringify(formData),
            headers: {
                Accept: "application/json"
            }
        }

        try {
            const res = await fetch(URL, params);
            const data = await res.json();

            if(data.ok === true) {
                setLoading(false);
                setFormData(DEFAULT_STATE);
                setFormSent(true);
            }

            if(data.ok === false) {
                setError(true);
                setLoading(false);
            }
        } catch(err) {
            setError(true);
            setLoading(false);
        }
    }

    return <form className="contact-form relative bg-sky-950 p-4 px-2 mx-4 md:m-auto rounded-xl overflow-hidden md:max-w-2xl md:p-4" onSubmit={handleSubmit}>
        {loading && <div className="loading-modal absolute top-0 left-0 w-full h-full bg-zinc-900 opacity-90 flex items-center justify-center"><img src={loader} className="loader h-32 w-32"/></div>} 
        {formSent && <div className="loading-modal absolute top-0 left-0 w-full h-full bg-zinc-900 opacity-90 flex items-center justify-center"><h3 className="text-white text-2xl text-center p-6">Thank you for your message.<br/> I will get back to you shortly!</h3></div>} 
        <h1 className="text-white text-xl bg-zinc-950 py-4 mt-4 -mx-20 border border-sky-600 pl-24 mb-6 md:mb-8 md:text-3xl">I'd love to hear from you...</h1>
        <div className="flex flex-col p-3 mb-1">
            <label htmlFor="name" className="label">Name:</label>
            <input type="text" className="form-input" value={formData.Name} name="Name" onChange={(e) => updateForm(e)} required/>
        </div>
        <div className="flex flex-col p-3 mb-1">
            <label htmlFor="email" className="label">Email address:</label>
            <input type="email" className="form-input" value={formData.Email} name="Email" onChange={(e) => updateForm(e)} required/>
        </div>
        <div className="flex flex-col p-3 mb-1">
            <label htmlFor="phone" className="label">Telephone (optional):</label>
            <input type="text" className="form-input" value={formData["Contact-Telephone"]} name="Contact-Telephone" onChange={(e) => updateForm(e)} />
        </div>
        <div className="flex flex-col p-3 mb-1">
            <label htmlFor="message" className="label">Your message:</label>
            <textarea className="form-textarea" value={formData.Message} name="Message" onChange={(e) => updateForm(e)}></textarea>
        </div>
        <input type="submit" className="submit-btn w-full rounded-lg text-blue-950 font-bold text-lg mt-4 mb-8 px-4 py-2 bg-amber-400 cursor-pointer"/>
        {error && <p className="bg-red-600 text-white -mx-4 px-4 py-2 text-center font-bold">Something went wrong. Please try again soon</p>} 
    </form>
}

export default ContactForm;