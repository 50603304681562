import music from "../../audio/index.js"

import c from "../../img/music-icons/c.png";
import d from "../../img/music-icons/d.jpg";
import des from "../../img/music-icons/des.jpg";
import gb from "../../img/music-icons/gb.jpg";
import lth from "../../img/music-icons/lth.jpg";
import pfImg from "../../img/music-icons/pf.jpg";
import ps from "../../img/music-icons/ps.jpg";

import prev from "../../img/player-icons/prev.png";
import next from "../../img/player-icons/next.png";

import { useState, useEffect, useRef } from "react";
import TrackImg from "./TrackImg.js";

const SONGS = {
    0: {
        title: "Desencontros",
        track: music[0],
        img: des,
    },
    1: {
        title: "Crystal",
        track: music[1],
        img: c,
    },
    2: {
        title: "Piano Sonata no.1 (in B minor)",
        track: music[2],
        img: ps,
    },
    3: {
        title: "Diluted",
        track: music[3],
        img: d,
    },
    4: {
        title: "Last Train Home",
        track: music[4],
        img: lth,
    },
    5: {
        title: "Phantom Fleeting",
        track: music[5],
        img: pfImg,
    },
    6: {
        title: "Groove B",
        track: music[6],
        img: gb,
    }
}

const AudioPlayer = () => {
    const [currentTrack, setCurrentTrack] = useState(0);
    const [playing, setPlaying] = useState(false);
    const [left, setLeft] = useState("50%");

    const audioElement = useRef(null);
    const trackDetailRef = useRef(null);

    const playNextTrack = () => {
        setCurrentTrack(prev => prev == Object.keys(SONGS).length - 1 ? 0 : parseInt(prev) + 1);
        playing && audioElement.current.play();
    }

    const playPrevTrack = () => {
        setCurrentTrack(prev => prev == 0 ? Object.keys(SONGS).length - 1 : prev - 1);
        playing && audioElement.current.play();
    }

    const selectTrack = (trackNumber) => {
        setCurrentTrack(trackNumber);
        setTimeout(() => {
            audioElement.current.play();
        }, 1000);
    }

    useEffect(() => {
        audioElement.current.addEventListener("ended", () => {
            playNextTrack();
            setTimeout(() => {
                audioElement.current.play();
            }, 1000)
        });
        alignSong(0);

        return () => {}
    }, [])

    useEffect(() => {
        alignSong(currentTrack)
    }, [currentTrack])

    const alignSong = (current) => {
        const childElements = Array.from(trackDetailRef.current.children);
        const targetElement = childElements.filter(el => el.id == "track-img-focus")[0];
        const halfWidth = targetElement.scrollWidth / 2
        const offset = targetElement.offsetLeft;
        const total = (trackDetailRef.current.scrollWidth / 2) - halfWidth - offset;
        setLeft(`${total}px`);
    } 

    return <section>
        <div className="flex relative w-4/5 h-88 m-auto mb-4 items-center justify-center overflow-x-scroll bg-zinc-950 opacity-90 rounded-xl">
            <div className="track-img-reel flex relative h-80 p-4 bg-transparent shadow-xl" ref={trackDetailRef} style={{ left: left }}>
                {Object.values(SONGS).map((obj, i) => {
                    return <TrackImg key={i} id={i} title={obj.title} img={obj.img} focus={currentTrack == i} setCurrentTrack={selectTrack}/>
                })}
            </div>
        </div>
        <div className="flex items-center justify-center h-14">
            <div className="flex items-center bg-sky-700 text-white font-bold px-3 py-1 h-full rounded-l-full cursor-pointer" onClick={() => playPrevTrack()}>
                <img src={prev} className="pointer-events-none"/>
            </div>
            <audio controls controlsList="nodownload" src={SONGS[currentTrack].track} ref={audioElement} className="inline bg-sky-700 h-full"/>
            <div className="flex items-center bg-sky-700 text-white font-bold px-3 py-1 h-full rounded-r-full cursor-pointer" onClick={() => playNextTrack()}>
                <img src={next} className="pointer-events-none"/>
            </div>
        </div>
    </section>
}

export default AudioPlayer;