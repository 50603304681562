import { useEffect, useRef } from "react";
import useWindowSize from "../../hooks/useWindowSize";

import Square from './Square';

const SquaresContainer = () => {
  const containerRef = useRef(null);
  const [windowSize, setWindowSize] = useWindowSize();
    const squareArray = Array.from({ length: windowSize < 1400 ? 1000 : 8000 }, (_, i) => i);

    const indexToPosition = (index) => {
      const colCount = windowSize < 1400 ? 100 : 200;
  
      const row = Math.floor(index / colCount);
      const col = index % colCount;
  
      return [col, row];
    }

    const positionToIndex = (col, row) => {
      const colCount = windowSize < 1400 ? 100 : 200;
  
      return (row * colCount) + col;
    }
    
    const setEffect = (index, outline=false) => {
      if (!outline) {
        containerRef.current.children[index].classList.add("hover-over");
      } else {
        setTimeout(() => {
          containerRef.current.children[index].classList.add("outline-box")
        }, 500)
      }

      setTimeout(() => {
        containerRef.current.children[index].classList.remove("hover-over", "outline-box");
      }, 3000);
    }

    const surrounding = (col, row) => {
      return [
        [col - 1, row - 1],
        [col - 1, row],
        [col - 1, row + 1],
        [col, row - 1],
        [col, row + 1],
        [col + 1, row - 1],
        [col + 1, row],
        [col + 1, row + 1]
      ].map(([c, r]) => positionToIndex(c, r)).filter(index => index > -1 && index < squareArray.length);
    }

    useEffect(() => {
        setInterval(() => {
          const indices = Array.from(containerRef.current.children).length;
          const randomIndex = Math.floor(Math.random() * indices);
          const [col, row] = indexToPosition(randomIndex);
          const surr = surrounding(col, row);

          setEffect(randomIndex);
          surr.forEach((index) => setEffect(index, true));
        }, 55)
      }, [])

     return ( 
        <div className="sq-container fixed opacity-50 top-0 -z-20 h-screen w-screen% bg-transparent" ref={containerRef}>
            {squareArray.map((_, i) => <Square key={i}/>)}
        </div>
    )
}

export default SquaresContainer;