import soundcloud from "../img/media-icons/soundcloud.png";

import AudioPlayer from "../components/audioplayer/AudioPlayer";
import Heading from "../components/ui/Heading";

const Music = () => {
    return <section className="flex p-4 mt-20 flex-col text-center items-center" id="Music">
        <div className="w-2/3 border border-sky-700" />
        <Heading>Musical compositions</Heading>
        <p className="text-white font-bold mt-10 md:max-w-4xl md:text-2xl md:font-normal">Below are some tracks that I have created over the years. My style is varied across classical, ambient and blues rock genres. Listen to my music on the player below or if you'd like to hear more, you can check out my soundcloud profile!</p>
        <a className="max-w-min m-auto text-center" href="https://soundcloud.com/vincent-coraldean" target="_blank" referrerPolicy="noreferrer"><button className="bg-white border border-amber-600 text-amber-600 w-72 py-2 rounded m-auto mt-6 mb-12 font-bold"><img src={soundcloud} alt="soundcloud logo" className="w-12 inline mr-2"/>Visit Soundcloud</button></a>
        <AudioPlayer />
    </section>
}

export default Music;