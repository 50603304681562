const music = {
    "0": require("./desencontros.mp3"),
    "1": require("./crystal.wav"),
    "2": require("./piano_sonata_no_1_in_b_minor.mp3"),
    "3": require("./diluted.mp3"),
    "4": require("./last_train_home.wav"),
    "5": require("./phantom_fleeting.mp3"),
    "6": require("./groove_b.mp3"),
}

export default music;