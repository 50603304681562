import highlightsData from "../components/portfolio/highlightsData";

import HighlightItem from "../components/portfolio/HighlightItem";
import Heading from "../components/ui/Heading";

import gh from "../img/media-icons/github.png";

const Github = () => {
    return <section id="Github" className="mt-20">
        <div className="w-2/3 border border-sky-400 m-auto"/>
        <Heading>Check out my Github repos</Heading>
        <div className="flex flex-col h-full md:flex-row md:items-stretch md:justify-around md:mx-20 md:my-20">
            <div className="bg-sky-400 flex items-center justify-center m-auto mt-10 w-full md:w-80 md:rounded-l-full md:flex-grow md:m-0">
                <a href="https://github.com/vincor1986/" target="_blank" referrerPolicy="noreferrer">
                    <img className="h-20 w-20 m-auto md:h-auto md:w-1/2" src={gh} alt="github logo" />
                </a>
            </div>
            <p className="bg-zinc-950 p-4 rounded-xl text-white text-center m-auto mt-10 mb-12 md:text-2xl md:max-w-4xl md:text-left md:m-0 md:rounded-l-none">I put most of the stuff I create on GitHub for others to see and use.
            <br/><br/>
            I enjoy building software with functionality, gathering data through APIs and processing information with JavaScript. I have built a range of things, from a crossword generator and a Wordle clone, to a cryptocurrency trading smart contract on the Binance Smart Chain blockchain network.
            <br/><br/>
            If you are interested in checking out my repositories, you can view them <a href="https://github.com/vincor1986?tab=repositories" target="_blank" referrerPolicy="noreferrer" className="text-amber-500 underline">here</a>. In many cases, there are links to live demonstrations in the README file.
            </p>
        </div>
        <h3 className="text-white stand-out text-center text-xl mb-10 md:text-3xl">-- Highlights from Github --</h3>
        <div className="m-auto flex flex-col p-4 bg-zinc-900 opacity-80 mb-12 md:p-12 md:flex-row md:flex-wrap md:justify-center md:gap-8">
            {highlightsData.map((item, i) => {
                return <HighlightItem item={item} key={i}/>
            })}
        </div>
        <a href="https://github.com/vincor1986/" target="_blank" referrerPolicy="noreferrer"><button className="w-72 py-2 text-black bg-white border-black font-bold rounded flex items-center justify-center text-center m-auto"><img src={gh} className="inline h-8 w-8 mr-3" />Visit Github</button></a>
    </section>
}

export default Github;