import { useState, useEffect } from "react";
import useWindowSize from "../../hooks/useWindowSize";

import NavLink from "../ui/NavLink";
import BurgerLink from "../ui/BurgerLink";


const LINKS = [
    "Web Development",
    "Tech Stack",
    "Github",
    "Music",
    "Contact"
]

const Navbar = () => {
    const [activeTab, setActiveTab] = useState("");
    const [showBurger, setShowBurger] = useState(false);
    const [showMenu, setShowMenu] = useState(false);

    const [windowSize, _] = useWindowSize();

    useEffect(() => {
        if (windowSize < 1000) {
            setShowBurger(true);
        } else {
            setShowBurger(false);
        }
    }, [windowSize])

    const autoScroll = (target) => {
        document.getElementById(target).scrollIntoView(true);
    }

    return <nav className="navbar fixed top-0 h-16 w-screen bg-black text-white px-4 md:px-16 py-4 flex justify-between z-20  xl:max-w-[1400px] xl:left-1/2 xl:-translate-x-[50%]">
        <h3 className="stand-out text-xl">Vincent Coraldean</h3>
        <div className="flex justify-evenly">
            {!showBurger && LINKS.map((link, i) => {
                return <NavLink key={i} tabName={link} active={activeTab == link} setActive={setActiveTab} autoScroll={autoScroll}/>
            })}
        </div>
        {showBurger && <div className="burger-icon" onClick={() => setShowMenu(prev => !prev)}>
            <span className="line"></span>
            <span className="line"></span>
            <span className="line"></span>
        </div>}
        {showMenu && <div className="fixed top-0 left-0 w-screen h-screen bg-zinc-900 opacity-80 -z-30" />}
        <div className={`${showMenu ? "show-menu" : ""} burger-menu fixed top-16 w-2/3 h-screen bg-black z-100`}>
            {showBurger && LINKS.map((link, i) => {
                return <BurgerLink key={i} tabName={link} active={activeTab == link} setActive={setActiveTab} autoScroll={autoScroll} setShowMenu={setShowMenu}/>
            })}
        </div>
    </nav>
}

export default Navbar;