import './App.css';

import Navbar from './components/layout/Navbar';
import SquaresContainer from './components/layout/SquaresContainer';
import Hero from './sections/Hero';
import Portfolio from './sections/Portfolio';
import Music from './sections/Music';
import Contact from "./sections/Contact";
import Footer from "./components/layout/Footer";
import Github from './sections/Github';

import useWindowSize from './hooks/useWindowSize';
import Tech from './sections/Tech';

const App = () => {
  const [windowSize, setWindowSize] = useWindowSize();

  return <div className="App">
    <Navbar />
    {/* <Background /> */}
    {windowSize > 1000 && <SquaresContainer />}
    <div className="w-[100%] xl:max-w-[1400px] xl:mx-auto">
      <Hero />
      <Portfolio />
      <Tech />
      <Github />
      <Music />
      <Contact />
      <Footer />
    </div>
  </div>
}

export default App;
