const FocalImage = ({ img, close }) => {
    return <div className="fixed w-full h-full top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 bg-zinc-950 bg-opacity-90 flex items-center justify-center z-20">
        <div className="close-x h-6 w-6 absolute top-5 right-3 flex-col justify-around cursor-pointer origin-center hover:rotate-90 transition-all duration-300 ease-in-out" onClick={close}>
            <div className="relative top-2 close-line h-1 w-full bg-white rotate-45 pointer-events-none"/>
            <div className="relative close-line h-1 w-full bg-white -rotate-45 top-1 pointer-events-none"/>
        </div>
        <img className="relative max-h-96 md:max-h-fit md:h-4/5  object-contain" src={img} alt="screenshot"/>
    </div>
}

export default FocalImage;