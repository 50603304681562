import lexical from "../../img/portfolio/highlights/lexical.png";
import crossword from "../../img/portfolio/highlights/crossword.png";
import geocities from "../../img/portfolio/highlights/geocities.png";

const highlightsData = [
    {
        title: "geoCities",
        description: "An interactive game where you have to match the city to the country (or vice-versa). The game includes a dynamic SVG atlas using latitude and longitude to locate cities. (Desktop only)",
        img: geocities,
        url: "https://vincor1986.github.io/geocities"
    },
    {
        title: "Crossword Generator",
        description: "An interactive crossword game, with new crosswords generated at random each time using a dictionary API, using the dictionary definitions as clues and you can change the difficulty setting.",
        img: crossword,
        url: "https://vincor1986.github.io/crossword-generator"
    },
    {
        title: "Lexical",
        description: "A wordle clone, using a dictionary API to choose words at random. There's no per day limit, you can change the length of the word and there's even a timed mode for the adventurous.",
        img: lexical,
        url: "https://vincor1986.github.io/lexical"
    },
]

export default highlightsData;