const HighlightItem = ({ item: { title, description, url, img } }) => {
  return (
    <div className="flex flex-col items-center justify-center mb-12">
      <h1 className="bg-sky-700 stand-out text-white text-xl text-center px-4 rounded-lg">
        {title}
      </h1>
      <div className="grid grid-rows-4 rounded-lg overflow-hidden mt-4 md:grid-rows-1 md:grid-cols-5 md:max-w-2xl">
        <img
          className="row-span-2 h-full w-full object-cover md:row-span-1 md:col-span-2"
          src={img}
          alt="demo image"
        />
        <p className="bg-zinc-800 text-white p-4 text-left row-span-2 md:rows-span-1 md:col-span-3">
          {description}
          <br />
          <br />
          <a
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            className="text-amber-500 underline mt-4 text-right cursor-pointer hover:text-amber-400"
          >
            {url}
          </a>
        </p>
      </div>
    </div>
  );
};

export default HighlightItem;
