import { useState, useEffect, useRef } from "react";
import useWindowSize from "../hooks/useWindowSize";

import techData from "../components/layout/techData";

import TechItem from "../components/layout/TechItem";
import Heading from "../components/ui/Heading";

const Tech = () => {
    const [activeItem, setActiveItem] = useState(0);
    const [hover, setHover] = useState(false);

    const [windowSize, _] = useWindowSize();

    const containerRef = useRef();

    const focusOnOne = (index) => {
        const items = Array.from(containerRef.current.children);
        items.forEach(item => {
            item.classList.remove("tech-item-active");
        })
        items[index].classList.add("tech-item-active");
    }

    const resetFocus = () => {
        const items = Array.from(containerRef.current.children);
        items.forEach((item) =>{
            item.classList.add("tech-item-active");
        })
    }

    useEffect(() => {
        if (hover) {
            focusOnOne(activeItem);
        } else {
            resetFocus();
        }
    }, [hover])

    const msg = `${windowSize > 1000 ? "Hover over" : "Click"} a logo to see a detailed description here`;

    const detail = activeItem !== null && <p><span className="text-bold stand-out">{techData[activeItem].title} - {techData[activeItem].description}</span></p>

    return <section id="Tech Stack" className="mt-20">
                <div className="w-2/3 border border-sky-400 m-auto"/>
        <Heading>Technologies I Use</Heading>
        <p className="text-lg text-center text-white px-10 py-4 mt-4 md:text-2xl md:mt-8 md:px-10">Check out the tech that I use below. I'm always learning new technologies, but these are the technologies I am currently familiar and proficient with.
        </p>
        <div className="w-full flex flex-row flex-wrap items-center justify-center px-10 mt-10 mb-10 md:px-20" ref={containerRef}>
            {techData.map((item, index) => <TechItem item={item} index={index} key={index} setActive={setActiveItem} setHover={setHover} active={activeItem == index}/>)}
        </div>
        <div className="mt-6 bg-zinc-800 text-white text-center py-2 px-4 md:px-10 md:text-xl md:py-6 md:w-3/5 md:m-auto md:rounded-md">
            {!hover ? msg : detail} 
        </div>
        
    </section>


}

export default Tech;