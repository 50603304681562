import Skill from "./Skill";

const PortfolioItem = ({ item: { desktop, mobile, description, url, skills, title }, setFocus }) => {
    return <div className="relative flex flex-col pt-20 my-4 md:mx-10 md:w-2/5">
        <div className="relative flex justify-center items-center p-10 pr-5 pt-20 pl-28 pb-20 bg-sky-950 text-white text-right md:pb-40 md:rounded-lg">
        <h2 className="absolute top-4 right-5 text-white m-auto text-2xl mb-4">{title}</h2>{description}{mobile && <img className="absolute bottom-4 left-5 w-20 z-10 cursor-pointer" src={mobile} onClick={setFocus} alt="screenshot on mobile device"/>}
            <div className="absolute -left-2 -top-12 h-36 w-36 rounded-full bg-sky-500 flex items-center justify-center">
                <img src={desktop} className="relative cursor-pointer" onClick={setFocus} alt="screenshot showing on laptop"/>
                    

            </div>
        <a href={url} target="_blank" referrer="noreferrer" rel="noreferrer"><button className="absolute bottom-4 right-5 bg-amber-500 py-2 px-4 rounded-lg text-md text-black font-bold cursor-pointer">Visit {title}</button></a>
        </div>
        <div className="flex flex-wrap px-2">{skills.map((skill, index) => <Skill key={index}>{skill}</Skill>)}</div>
    </div>
}   

export default PortfolioItem;