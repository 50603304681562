import me from "../img/me.jpg";

const Hero = () => {
    return <section className="relative m-auto justify-center items-center">
        <h1 className="text-amber-400 bg-zinc-950 bg-opacity-80 rounded-lg p-4 m-auto max-w-xs mt-16 text-center text-2xl md:text-5xl md:mt-44 md:max-w-5xl">Hi! I'm Vince, a freelance web developer and music composer from London, UK.</h1>
        <div  className="relative flex flex-col md:flex-row-reverse m-auto justify-center items-center"> 
        <div className="hero-img-wrapper relative rounded-full overflow-hidden border-4 border-amber-400 mt-10 z-10  md:max-w-xs"><img src={me} alt="me" className="w-full h-full hero-img"/></div>
        <div className="relative hero-msg text-white p-6 text-center max-w-xs -mt-5 bg-zinc-950 rounded-lg md:mr-12 md:text-right md:w-96">
            <div className="hero-msg-bg absolute -top-3 left-0 h-8 w-full rounded-lg -z-10"></div>
            <h2>I make creative experiences that will resonate with your audience</h2>
        </div></div>
        <h3 className="text-xl text-amber-400 text-center max-w-fit m-auto mt-20">Scroll down to see my work{"  "}<span className="arrow-down-symbol">↓</span></h3>  
    </section>
}

export default Hero;