import bspqDesktop from "../../img/portfolio/bspq/desktop.png"
import bspqMobile from "../../img/portfolio/bspq/mobile.png"

import hotelDesktop from "../../img/portfolio/hotel/desktop.png";
import hotelMobile from "../../img/portfolio/hotel/mobile.png";

import bwdDesktop from "../../img/portfolio/bwd/desktop.png";
import bwdMobile from "../../img/portfolio/bwd/mobile.png";

import rsDesktop from "../../img/portfolio/researchspan/desktop.png";
import rsMobile from "../../img/portfolio/researchspan/mobile.png";

import chartDesktop from "../../img/portfolio/chartJS/desktop.png";

const portfolioData = [
    {
        title: "Bespoqa.io",
        description: "An AI SaaS that enables users to upload text and create an embeddable HTML AI Chatbot that is an expert in that text corpus. The site includes a payment gateway, a user dashboard and offers analytics on chat trends.",
        skills: ["API Integration", "MERN Stack", "Node.js", "Artificial Intelligence", "ChatGPT"],
        mobile: bspqMobile,
        desktop: bspqDesktop,
        url: "https://bespoqa.io/",
    },
    {
        title: "L'Hotel du Luxe",
        description: "A website for a fictional hotel in Paris. I wanted to reflect a unique and well-established business identity through the colour scheme and page styling. Features an About page, booking UI and Contact page.",
        skills: ["HTML5","Web Development", "JavaScript", "CSS"],
        mobile: hotelMobile,
        desktop: hotelDesktop,
        url: "https://vincor1986.github.io/hotel-website/",
    },
    {
        title: "Blockchain",
        description: "An example landing page website for a blockchain web development organisation, including conversion maximisation priciples such as AIDA funnel.",
        skills: ["HTML5", "React.js", "JavaScript", "CSS", "Blockchain"],
        mobile: bwdMobile,
        desktop: bwdDesktop,
        url: "https://vincor1986.github.io/blockchain-web-dev/",
    },
    {
        title: "Research Span",
        description: "A social media platform for researchers and recruiters, built using the MERN stack (Mongo DB, Express.js, React.js and Node.js).",
        skills: ["Full Stack Development", "RESTful API","MongoDB", "API Integration", "MERN Stack"],
        mobile: rsMobile,
        desktop: rsDesktop,
        url: "https://researchspan.fly.dev",
    },
    {
        title: "ChartJS Demo",
        description: "An overview of popular chart types available from ChartJS (using React and TailwindCSS). These charts are fully customisable and can be programmatically fed with data from API calls or databases.",
        skills: ["HTML5", "React.js", "Tailwind CSS", "ChartJS"],
        mobile: null,
        desktop: chartDesktop,
        url: "https://vincor1986.github.io/react-visualisations/",
    },
]

export default portfolioData;