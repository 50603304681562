import { useEffect, useState } from "react";

const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState(window.screen.availWidth);

    const width = window.screen.availWidth;

    useEffect(() => {
        if (width !== windowSize) {
            setWindowSize(width)
        }
    }, [width])

    return [windowSize, setWindowSize];
}

export default useWindowSize;

