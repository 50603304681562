import { useState } from "react";

import portfolioData from "../components/portfolio/portfolioData";


import PortfolioItem from "../components/portfolio/PortfolioItem";
import Heading from "../components/ui/Heading";
import FocalImage from "../components/layout/FocalImage";

const Portfolio = () => {
    const [showImg, setShowImg] = useState(false);
    const [focalImg, setFocalImg] = useState(null);

    const setFocus = (e) => {
        const img = e.target.src;
        setFocalImg(img);
        setShowImg(true);
    }

    const closeFocus = () => {
        setFocalImg(null);
        setShowImg(false);
    }

    return <section id="Web Development">
        {showImg && <FocalImage img={focalImg} close={closeFocus}/>}
        <div className="w-2/3 border border-sky-700 m-auto" />
        <Heading>See my web development work</Heading>
        <p className="text-white text-center p-8 mt-5 m-auto w-full md:text-2xl">Below are a few examples of my work. I've been a freelance full-stack web developer for just over two years, with a focus on single-page applications (SPAs) and landing pages. I predominantly use React.js and Next.js for front-end applications, and use Next.js and/or the MERN stack (MongoDB, Express.js, React.js and Node.js) for full-stack applications. <br/><br/>
        Please feel free to explore my work and get in touch using the contact form if you'd like to work with me on your upcoming project!<br/>
        <br/>
        <span className="text-amber-400">Click images to enlarge</span></p>
        
        <div className="flex flex-col md:flex-row md:flex-wrap justify-center">
        {portfolioData.map((item, i) => {
            return <PortfolioItem item={item} key={i} setFocus={setFocus} />
        })}
        </div>
    </section>
}

export default Portfolio;