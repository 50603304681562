import ContactForm from "../components/form/ContactForm";
import Heading from "../components/ui/Heading";

const Contact = () => {
    return <section id="Contact" className="mt-20">
        <div className="w-2/3 border border-sky-400 m-auto"/>
        <Heading>Contact me today!</Heading>
        <p className="bg-zinc-950 p-4 rounded-xl text-white text-center m-auto mt-6 mb-12 md:text-2xl md:max-w-4xl">If you'd like to get in touch to arrange a consultation or to discuss working together on a project, please feel free to email me at <a href="mailto:info@vincentcoraldean.com" className="underline text-amber-400">info@vincentcoraldean.com</a> or complete the below contact form. <br/><br/> I look forward to hearing from you!</p>
        <ContactForm />
    </section>
}

export default Contact;